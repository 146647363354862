import React from 'react'

import { TextField, Autocomplete } from '@mui/material'
import { SxProps } from '@mui/system'
import { KeyboardArrowDown } from '@mui/icons-material'
import { Theme } from '@mui/material/styles'

export interface AutocompleteOption {
    label: string
    value: string | number
}

interface AutocompleteFieldProps {
    options: AutocompleteOption[]
    value?: AutocompleteOption
    label?: string
    disabled?: boolean
    placeholder: string
    onChange: (value: string | number | string[] | number[]) => void
    state?: { touched: boolean; errors: undefined | string | string[] }
    sx?: SxProps<Theme>
}

const AutocompleteField = ({
    options,
    value,
    state,
    onChange,
    label,
    placeholder = 'Select',
    sx = {},
    disabled = false,
}: AutocompleteFieldProps) => {
    return (
        <Autocomplete
            sx={{
                '& .MuiInputBase-root': {
                    borderRadius: '6px',
                    background: `#fff !important`,
                    '& input': {
                        width: 0,
                        color: '#1D1E20',
                    },
                },
                ...sx,
            }}
            disabled={disabled}
            openOnFocus
            disableClearable
            options={options}
            value={value}
            onChange={(e, value) => {
                onChange(value.value)
            }}
            isOptionEqualToValue={(o, v) => o.value === v.value}
            fullWidth
            renderOption={(props, option, { selected }) => {
                return (
                    <li
                        {...props}
                        style={{
                            fontSize: '15px',
                            ...(selected && { color: '#1C64F2' }),
                        }}
                    >
                        {option.label}
                    </li>
                )
            }}
            renderInput={params => {
                return (
                    <TextField
                        {...params}
                        label={label}
                        placeholder={placeholder}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        error={state && state.touched && Boolean(state.errors)}
                        helperText={state && state.touched && state.errors}
                    />
                )
            }}
            popupIcon={<KeyboardArrowDown />}
        />
    )
}

export default AutocompleteField
