import { object, string, number, boolean } from 'yup'

export const PostSignUpFormInitialValues = {
    school: '',
    location: null,
    grade: null,
    nonStudent: false,
}

export const PostSignUpFormScheme = object({
    location: number().required('Location is required'),
    school: string().when('nonStudent', {
        is: true,
        then: schema => schema.nullable(),
        otherwise: schema => schema.required('School is required'),
    }),

    grade: number().when('nonStudent', {
        is: true,
        then: schema => schema.nullable(),
        otherwise: schema =>
            schema
                .min(1, 'Should be higher than or equal to 1')
                .max(5, 'Should be less than or equal to 5')
                .required('Your should specify your grade'),
    }),
    nonStudent: boolean().required(),
})
