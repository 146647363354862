import React from 'react'

import { Box, Button, Checkbox, Stack, Typography } from '@mui/material'
import { AccessTime } from '@mui/icons-material'

import { Image } from 'components/common'
import { UserType } from 'api/generated'
import star from 'assets/images/star.svg'
import briefcase from 'assets/images/briefcase.svg'
import chat from 'assets/images/chat.svg'
import noImage from 'assets/images/event-no-image.jpg'
import checkbox from 'assets/images/checkbox.svg'
import checkboxChecked from 'assets/images/checkbox-checked.svg'
import { getFullName } from 'utils/getFullname'

interface UserCardProps {
    onClick: (id: string) => void
    data: UserType
    selectable?: boolean
    selected?: boolean
    pending?: boolean
    invitable?: boolean
    onSelect?: (id: string, selected: boolean) => void
    onInvite?: (user: UserType) => void
}

const UserCard = ({
    onClick,
    data,
    onSelect,
    selectable,
    pending,
    invitable,
    onInvite,
    selected,
}: UserCardProps) => {
    return (
        <Stack
            data-test-id="user-card"
            gap="16px"
            sx={{
                padding: '12px',
                borderRadius: '9px',
                border: '1px solid #E5E7EB',
                cursor: 'pointer',
                '&:hover': {
                    border: '1px solid #8c8c8c',
                },
            }}
            onClick={() => onClick(data.id)}
        >
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    maxHeight: '247px',
                    position: 'relative',
                    zIndex: 1,
                }}
            >
                <Image
                    data-test-id="avatar"
                    src={data?.avatar?.img247x247 || noImage}
                    sx={{
                        borderRadius: '9px',
                        objectFit: 'cover',
                        width: '100%',
                        minWidth: '247px',
                        height: '247px',
                    }}
                />
                {selectable && !pending && (
                    <Checkbox
                        data-test-id="checkbox"
                        icon={<Image src={checkbox} />}
                        checkedIcon={<Image src={checkboxChecked} />}
                        checked={selected}
                        onChange={(_, checked) => {
                            onSelect?.(data.id, checked)
                        }}
                        onClick={e => e.stopPropagation()}
                        sx={{
                            position: 'absolute',
                            top: '8px',
                            left: '8px',
                            padding: '5px',
                            '& > img': {
                                width: '28px',
                                height: '28px',
                            },
                        }}
                    />
                )}
            </Box>
            <Typography
                fontSize="15px"
                lineHeight="19px"
                fontWeight={600}
                color="#131313"
                data-test-id="name"
            >
                {`${getFullName(data.firstName, data.lastName)}` || 'No title'}
            </Typography>
            <Stack gap="10px">
                <Stack direction="row" gap="8px">
                    <Image src={briefcase} sx={{ flexBasis: '20px' }} />
                    <Typography
                        fontSize="13px"
                        lineHeight="18px"
                        color="#384853"
                        data-test-id="school"
                    >
                        {data?.school?.name || 'N/A'}
                    </Typography>
                </Stack>
                <Stack direction="row" gap="8px">
                    <Image src={star} sx={{ flexBasis: '20px' }} />
                    <Typography
                        fontSize="13px"
                        lineHeight="18px"
                        color="#384853"
                        data-test-id="largest-organized-club"
                    >
                        {data?.largestOrganizedClub?.name || 'N/A'}
                    </Typography>
                </Stack>
                <Stack direction="row" gap="8px">
                    <Image src={chat} sx={{ flexBasis: '20px' }} />
                    <Typography
                        fontSize="13px"
                        lineHeight="18px"
                        color="#384853"
                        data-test-id="latest-event-participation"
                    >
                        {data?.latestEventParticipation?.name || 'N/A'}
                    </Typography>
                </Stack>
            </Stack>
            <Stack
                direction="row"
                justifyContent="space-between"
                padding="15px 16px"
                sx={{ backgroundColor: '#F3F4F6', borderRadius: '9px' }}
            >
                <Stack gap="3px">
                    <Typography
                        fontSize="12px"
                        lineHeight="16px"
                        color="#6B7B8A"
                    >
                        Interests
                    </Typography>
                    <Typography
                        fontSize="13px"
                        lineHeight="17px"
                        color="#384853"
                        fontWeight={600}
                        data-test-id="interests"
                    >
                        {data.interests?.length
                            ? data.interests
                                  ?.filter((_, i) => i < 2)
                                  ?.map((i, idx, arr) => i?.val)
                                  .join(', ')
                            : '-'}
                    </Typography>
                </Stack>
                <Stack gap="3px">
                    <Typography
                        fontSize="12px"
                        lineHeight="16px"
                        color="#6B7B8A"
                    >
                        Food
                    </Typography>
                    <Typography
                        fontSize="13px"
                        lineHeight="17px"
                        color="#384853"
                        fontWeight={600}
                        data-test-id="food"
                    >
                        {data.foodPreferences?.length
                            ? data.foodPreferences
                                  ?.map((i, idx, arr) => i?.val)
                                  .join(', ')
                            : '-'}
                    </Typography>
                </Stack>
            </Stack>

            {pending && (
                <Button
                    data-test-id="pending-status"
                    endIcon={<AccessTime />}
                    variant="outlined"
                    sx={{ color: '#131313', pointerEvents: 'none' }}
                >
                    Pending...
                </Button>
            )}

            {invitable && !pending && (
                <Button
                    data-test-id="invite-button"
                    onClick={() => {
                        onInvite?.(data)
                    }}
                >
                    Invite User
                </Button>
            )}
        </Stack>
    )
}

export default UserCard
