import React, { ReactNode, useEffect, useState } from 'react'

import {
    Button,
    Checkbox,
    ClickAwayListener,
    Divider,
    FormControlLabel,
    InputBase,
    LinearProgress,
    Popper,
    Slider,
    Stack,
    Typography,
} from '@mui/material'
import { useMediaQuery } from '@mui/system'
import { KeyboardArrowDown } from '@mui/icons-material'
import { DateCalendar } from '@mui/x-date-pickers'
import { format } from 'date-fns'

import {
    AutocompleteOption,
    MultipleAutocompleteField,
} from 'components/inputs'
import { Image } from 'components/common'
import { useConfig } from 'hooks/config'
import { useDebounced } from 'hooks/common'
import { ClubType, EventType } from 'api/generated'
import { useUser } from 'hooks/user'
import checkbox from 'assets/images/checkbox.svg'
import checkboxChecked from 'assets/images/checkbox-checked.svg'
import { useUserSearchFiltersData } from 'hooks/search'

export interface FilterState {
    search?: string
    interests?: number[]
    schools?: string[]
    clubs?: string[]
    events?: string[]
    gradeFrom?: number
    gradeTo?: number
    startFrom?: string
    startTo?: string
    nonStudent?: boolean
    noGrade?: boolean
}

interface SearchPageLayoutProps {
    children?: JSX.Element | JSX.Element[] | ReactNode
    caption: [number, number, string] | null
    onChangeFilters: (filter: FilterState) => void
    defaultFilterState: FilterState
    loading?: boolean
    filters?: string[]
    group?: ClubType | EventType
    selectable?: boolean
    allSelected?: boolean
    selectedCount?: number
    subheader?: string
    onInviteUsers?: () => void
    onCancelInvite?: () => void
    onSelectAll?: () => void
    onDeselectAll?: () => void
    type: 'events' | 'users' | 'clubs'
}

// TODO: Add LOCATION and DATE filters
const SearchPageLayout = ({
    type,
    children,
    caption,
    onChangeFilters,
    filters = [],
    loading = false,
    allSelected = false,
    group,
    selectable,
    selectedCount,
    onInviteUsers,
    onCancelInvite,
    onSelectAll,
    onDeselectAll,
    subheader,
    defaultFilterState = {},
}: SearchPageLayoutProps) => {
    const { user } = useUser()
    const { data } = useUserSearchFiltersData(type !== 'users')
    const { data: config } = useConfig({
        skip: !filters?.includes('grade') || !filters?.includes('interests'),
    })
    const lessThan1250px = useMediaQuery('(max-width:1250px)')
    const lessThan1100px = useMediaQuery('(max-width:1100px)')
    const lessThan800px = useMediaQuery('(max-width:800px)')
    const lessThan700px = useMediaQuery('(max-width:700px)')

    const [filter, setFilter] = useState<FilterState>({
        gradeFrom: 1,
        gradeTo: 6,
        search: '',
        nonStudent: false,
        ...defaultFilterState,
    })
    const [searchText, setSearchText] = useState(
        defaultFilterState?.search || ''
    )
    const debouncedSearchText = useDebounced(searchText, 500)

    const [gradeAnchorEl, setGradeAnchorEl] =
        useState<HTMLButtonElement | null>(null)
    const [isGradeOpen, setGradeOpen] = useState(false)
    const [dateAnchorEl, setDateAnchorEl] = useState<HTMLButtonElement | null>(
        null
    )
    const [isDateOpen, setDateOpen] = useState(false)

    const handleOpenGrade = (event: React.MouseEvent<HTMLButtonElement>) => {
        setGradeAnchorEl(event.currentTarget)
        setGradeOpen(prev => !prev)
    }

    const handleOpenDate = (event: React.MouseEvent<HTMLButtonElement>) => {
        setDateAnchorEl(event.currentTarget)
        setDateOpen(prev => !prev)
    }

    useEffect(() => {
        setFilter(prevState => ({
            ...prevState,
            search: debouncedSearchText,
        }))
    }, [debouncedSearchText])

    useEffect(() => {
        onChangeFilters(filter)
    }, [filter])

    const schoolOptions =
        data?.schools?.edges
            .map(e => e?.node)
            ?.map(s => ({
                value: s?.id as string,
                label: s?.name as string,
            })) || []

    const interestOptions =
        config?.config?.interests?.map(s => ({
            value: s?.key as number,
            label: s?.val as string,
        })) || []

    const clubOptions =
        data?.clubs?.edges
            .map(e => e?.node)
            .map(s => ({
                value: s?.id as string,
                label: s?.name as string,
            })) || []

    const eventOptions =
        data?.events?.edges
            .map(e => e?.node)
            ?.map(s => ({
                value: s?.id as string,
                label: s?.name as string,
            })) || []

    const grades =
        config?.config?.grades?.map(g => ({
            label: g?.val as string,
            value: g?.key as number,
        })) || []

    const gradeFromTitle = config?.config?.grades?.find(
        g => g?.key === filter.gradeFrom
    )?.val
    const gradeToTitle = config?.config?.grades?.find(
        g => g?.key === filter.gradeTo
    )?.val

    const resolveSelectedDates = (startFrom?: string, startTo?: string) => {
        const from = startFrom
            ? format(new Date(startFrom.split('T')[0]), 'MM/dd/yyyy')
            : 'Date from'

        const to = startTo
            ? format(new Date(startTo.split('T')[0]), 'MM/dd/yyyy')
            : 'Date to'
        return `${from} - ${to}`
    }

    return (
        <Stack>
            {selectable && (
                <Stack
                    data-test-id="selectable-header"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{
                        borderBottom: '1px solid #E4E4EB',
                        boxShadow: '0px 2px 6px 0px rgba(3, 30, 38, 0.04)',
                        width: '100%',
                        position: 'sticky',
                        top: 0,
                        left: 0,
                        right: 0,
                        zIndex: 3,
                        backgroundColor: '#fff',
                        padding: '12px 121px',
                        ...(lessThan1100px && {
                            padding: '10px 70px',
                        }),
                        ...(lessThan700px && {
                            padding: '8px 10px',
                        }),
                    }}
                >
                    <Typography
                        fontSize={lessThan700px ? '13px' : '16px'}
                        color="#1c1e27"
                        lineHeight="15px"
                        fontWeight={500}
                        data-test-id="selected-count"
                    >
                        {selectedCount} selected
                    </Typography>
                    <Stack
                        gap="24px"
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        {allSelected ? (
                            <Button
                                data-test-id="deselect-all-button"
                                variant="text"
                                onClick={onDeselectAll}
                                sx={{
                                    fontSize: lessThan700px ? '13px' : '16px',
                                }}
                            >
                                Deselect All
                            </Button>
                        ) : (
                            <Button
                                data-test-id="select-all-button"
                                variant="text"
                                onClick={onSelectAll}
                                sx={{
                                    fontSize: lessThan700px ? '13px' : '16px',
                                }}
                            >
                                Select All
                            </Button>
                        )}
                        <Button
                            data-test-id="cancel-button"
                            variant="text"
                            sx={{
                                color: '#000',
                                fontSize: lessThan700px ? '13px' : '16px',
                            }}
                            onClick={onCancelInvite}
                        >
                            Cancel
                        </Button>
                        <Button
                            data-test-id="invite-button"
                            variant="contained"
                            onClick={onInviteUsers}
                            disabled={selectedCount === 0}
                            sx={{ fontSize: lessThan700px ? '13px' : '16px' }}
                        >
                            {lessThan700px ? 'Invite' : 'Invite users'}
                        </Button>
                    </Stack>
                </Stack>
            )}
            <Stack
                gap="36px"
                sx={{
                    position: 'relative',
                    padding: '60px 121px 40px',
                    ...(lessThan1100px && {
                        padding: '50px 70px 30px',
                    }),
                    ...(lessThan700px && {
                        padding: '20px 40px',
                    }),
                }}
            >
                <Stack gap={group || subheader ? '36px' : '24px'}>
                    {group && (
                        <Stack
                            direction="row"
                            gap="10px"
                            alignItems="center"
                            data-test-id="group"
                        >
                            <Image
                                data-test-id="cover"
                                src={group.cover?.img64x64 as string}
                                sx={{
                                    borderRadius: '8px',
                                    width: '52px',
                                    height: '52px',
                                }}
                            />
                            <Typography
                                fontSize="22px"
                                fontWeight={600}
                                data-test-id="name"
                            >
                                {group?.name}
                            </Typography>
                        </Stack>
                    )}
                    {subheader && (
                        <Stack direction="row" gap="10px" alignItems="center">
                            <Image
                                data-test-id="avatar"
                                src={user?.avatar?.img100x100 as string}
                                sx={{
                                    borderRadius: '8px',
                                    width: '52px',
                                    height: '52px',
                                }}
                            />
                            <Typography
                                fontSize="22px"
                                fontWeight={600}
                                data-test-id="subheader"
                            >
                                {subheader}
                            </Typography>
                        </Stack>
                    )}
                    <Stack
                        direction={lessThan1250px ? 'column' : 'row'}
                        justifyContent="space-between"
                        gap={lessThan1250px ? '25px' : 0}
                    >
                        <Stack
                            direction="row"
                            width="100%"
                            gap="12px"
                            flexWrap="wrap"
                            sx={{
                                '& > *': {
                                    minWidth: '100px',
                                    maxWidth: '200px',
                                    ...(lessThan1100px && {
                                        minWidth: '100px',
                                        maxWidth: '200px',
                                    }),
                                    ...(lessThan700px && {
                                        maxWidth: '280px',
                                    }),
                                },
                            }}
                        >
                            <Popper
                                open={isGradeOpen}
                                anchorEl={gradeAnchorEl}
                                placement="bottom-start"
                                disablePortal
                                sx={{
                                    paddingTop: '5px',
                                    zIndex: 2,
                                }}
                            >
                                <ClickAwayListener
                                    onClickAway={() => setGradeOpen(false)}
                                >
                                    <Stack
                                        width={
                                            lessThan700px ? '340px' : '400px'
                                        }
                                        padding="10px 40px"
                                        sx={() => ({
                                            borderRadius: '8px',
                                            border: '1px solid rgba(29, 30, 32, 0.2)',
                                            backgroundColor: '#fff',
                                        })}
                                    >
                                        <Slider
                                            data-test-id="grade-filter"
                                            sx={{
                                                '& .MuiSlider-markLabel': {
                                                    fontSize: '12px',
                                                },
                                            }}
                                            onChangeCommitted={(e, value) => {
                                                const [gradeFrom, gradeTo] =
                                                    value as number[]

                                                setFilter(prevState => ({
                                                    ...prevState,
                                                    gradeFrom,
                                                    gradeTo,
                                                }))
                                            }}
                                            value={[
                                                filter.gradeFrom || 1,
                                                filter.gradeTo || 6,
                                            ]}
                                            marks={[
                                                ...grades,
                                                {
                                                    label: 'All grades',
                                                    value: grades.length + 1,
                                                },
                                            ]}
                                            step={1}
                                            max={6}
                                            min={1}
                                        />
                                    </Stack>
                                </ClickAwayListener>
                            </Popper>
                            <Popper
                                open={isDateOpen}
                                anchorEl={dateAnchorEl}
                                placement={
                                    lessThan800px
                                        ? 'bottom-end'
                                        : 'bottom-start'
                                }
                                disablePortal
                                sx={{
                                    paddingTop: '5px',
                                    zIndex: 2,
                                    maxWidth: '100%',
                                }}
                            >
                                <ClickAwayListener
                                    onClickAway={() => setDateOpen(false)}
                                >
                                    <Stack
                                        padding={
                                            lessThan800px ? '10px' : '20px'
                                        }
                                        gap="12px"
                                        maxWidth={
                                            lessThan800px ? '400px' : '100%'
                                        }
                                        sx={() => ({
                                            borderRadius: '8px',
                                            border: '1px solid rgba(29, 30, 32, 0.2)',
                                            backgroundColor: '#fff',
                                        })}
                                    >
                                        <Stack
                                            divider={<Divider flexItem />}
                                            gap="10px"
                                            direction={
                                                lessThan800px ? 'column' : 'row'
                                            }
                                        >
                                            <Stack>
                                                <Typography
                                                    sx={{
                                                        opacity: '0.7',
                                                        paddingLeft: '24px',
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        lineHeight: '23px',
                                                    }}
                                                >
                                                    Date from
                                                </Typography>
                                                <DateCalendar
                                                    onChange={v => {
                                                        setFilter(
                                                            prevState => ({
                                                                ...prevState,
                                                                startFrom: `${format(
                                                                    v,
                                                                    'yyyy-MM-dd'
                                                                )}T00:00.000Z`,
                                                            })
                                                        )
                                                    }}
                                                    sx={{
                                                        height: 'auto',
                                                        ...(lessThan800px && {
                                                            width: '270px',
                                                            '& .MuiPickersCalendarHeader-label':
                                                                {
                                                                    fontSize:
                                                                        '14px',
                                                                },
                                                        }),
                                                        '& .MuiPickersSlideTransition-root':
                                                            {
                                                                minHeight:
                                                                    '200px',
                                                            },
                                                    }}
                                                />
                                            </Stack>
                                            <Stack>
                                                <Typography
                                                    sx={{
                                                        opacity: '0.7',
                                                        paddingLeft: '24px',
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        lineHeight: '23px',
                                                    }}
                                                >
                                                    Date to
                                                </Typography>
                                                <DateCalendar
                                                    onChange={v => {
                                                        setFilter(
                                                            prevState => ({
                                                                ...prevState,
                                                                startTo: `${format(
                                                                    v,
                                                                    'yyyy-MM-dd'
                                                                )}T23:59.000Z`,
                                                            })
                                                        )
                                                    }}
                                                    sx={{
                                                        height: 'auto',
                                                        ...(lessThan800px && {
                                                            width: '270px',
                                                            '& .MuiPickersCalendarHeader-label':
                                                                {
                                                                    fontSize:
                                                                        '14px',
                                                                },
                                                        }),
                                                        '& .MuiPickersSlideTransition-root':
                                                            {
                                                                minHeight:
                                                                    '200px',
                                                            },
                                                    }}
                                                />
                                            </Stack>
                                        </Stack>
                                    </Stack>
                                </ClickAwayListener>
                            </Popper>
                            {filters.includes('search') && (
                                <InputBase
                                    data-test-id="search-filter"
                                    fullWidth
                                    placeholder="Search"
                                    value={searchText}
                                    onChange={e =>
                                        setSearchText(e.target.value)
                                    }
                                    sx={{
                                        '& input': {
                                            '&::placeholder': {
                                                fontWeight: 500,
                                            },
                                        },
                                        ...(lessThan700px && {
                                            '& .MuiInputBase-input': {
                                                padding: '5px 14px',
                                            },
                                        }),
                                    }}
                                />
                            )}
                            {filters?.includes('interests') && (
                                <MultipleAutocompleteField
                                    dataTestId="interests-filter"
                                    sx={{
                                        ...(lessThan700px && {
                                            '& .MuiTextField-root': {
                                                '& .MuiInputBase-input': {
                                                    padding: '3px !important',
                                                },
                                            },
                                        }),
                                    }}
                                    placeholder="Interests"
                                    withSelectAll
                                    value={
                                        (interestOptions?.filter(i =>
                                            filter.interests?.includes(
                                                i?.value as number
                                            )
                                        ) || []) as AutocompleteOption[]
                                    }
                                    options={interestOptions || []}
                                    onChange={value =>
                                        setFilter(prevState => ({
                                            ...prevState,
                                            interests: value as number[],
                                        }))
                                    }
                                />
                            )}
                            {filters?.includes('school') && (
                                <MultipleAutocompleteField
                                    dataTestId="school-filter"
                                    sx={{
                                        ...(lessThan700px && {
                                            '& .MuiTextField-root': {
                                                '& .MuiInputBase-input': {
                                                    padding: '3px !important',
                                                },
                                            },
                                        }),
                                    }}
                                    placeholder="School"
                                    value={schoolOptions.filter(s =>
                                        filter.schools?.includes(
                                            s?.value as string
                                        )
                                    )}
                                    options={schoolOptions}
                                    onChange={value =>
                                        setFilter(prevState => ({
                                            ...prevState,
                                            schools: value as string[],
                                        }))
                                    }
                                />
                            )}
                            {filters?.includes('date') && (
                                <Button
                                    data-test-id="date-filter-button"
                                    onClick={handleOpenDate}
                                    endIcon={<KeyboardArrowDown />}
                                    sx={{
                                        maxWidth: '220px',
                                        ...(lessThan700px && {
                                            maxWidth: '280px',
                                        }),
                                        border: '1.25px solid #E0E3E7',
                                        borderRadius: '5px',
                                        backgroundColor: '#fff',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                        color: '#1D1E20',
                                        padding: '4px 10px',
                                        '&:hover': {
                                            background: 'transparent',
                                            color: '#1D1E20',
                                        },
                                        '& > .MuiButton-endIcon': {
                                            marginLeft: 0,
                                        },
                                        '& .MuiSvgIcon-root': {
                                            fontSize: '24px !important',
                                            color: '#000',
                                            opacity: '0.6',
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            opacity: '0.7',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            lineHeight: '23px',
                                        }}
                                    >
                                        {resolveSelectedDates(
                                            filter.startFrom,
                                            filter.startTo
                                        )}
                                    </Typography>
                                </Button>
                            )}
                            {filters?.includes('club') && (
                                <MultipleAutocompleteField
                                    dataTestId="club-filter"
                                    sx={{
                                        ...(lessThan700px && {
                                            '& .MuiTextField-root': {
                                                '& .MuiInputBase-input': {
                                                    padding: '3px !important',
                                                },
                                            },
                                        }),
                                    }}
                                    placeholder="Club"
                                    value={
                                        (clubOptions?.filter(i =>
                                            filter.clubs?.includes(
                                                i?.value as string
                                            )
                                        ) || []) as AutocompleteOption[]
                                    }
                                    options={clubOptions || []}
                                    onChange={value =>
                                        setFilter(prevState => ({
                                            ...prevState,
                                            clubs: value as string[],
                                        }))
                                    }
                                />
                            )}

                            {filters?.includes('event') && (
                                <MultipleAutocompleteField
                                    dataTestId="event-filter"
                                    sx={{
                                        ...(lessThan700px && {
                                            '& .MuiTextField-root': {
                                                '& .MuiInputBase-input': {
                                                    padding: '3px !important',
                                                },
                                            },
                                        }),
                                    }}
                                    placeholder="Event"
                                    value={
                                        (eventOptions?.filter(i =>
                                            filter.events?.includes(i?.value)
                                        ) || []) as AutocompleteOption[]
                                    }
                                    options={eventOptions || []}
                                    onChange={value =>
                                        setFilter(prevState => ({
                                            ...prevState,
                                            events: value as string[],
                                        }))
                                    }
                                />
                            )}
                            {filters?.includes('grade') && (
                                <Button
                                    data-test-id="grade-filter-button"
                                    disabled={
                                        filter.nonStudent || filter.noGrade
                                    }
                                    onClick={handleOpenGrade}
                                    endIcon={<KeyboardArrowDown />}
                                    sx={{
                                        border: '1.25px solid #E0E3E7',
                                        borderRadius: '5px',
                                        backgroundColor: '#fff',
                                        width: '100%',
                                        justifyContent: 'space-between',
                                        color: '#1D1E20',
                                        padding: '4px 10px',
                                        '&:hover': {
                                            background: 'transparent',
                                            color: '#1D1E20',
                                        },
                                        '& > .MuiButton-endIcon': {
                                            marginLeft: 0,
                                        },
                                        '& .MuiSvgIcon-root': {
                                            fontSize: '24px !important',
                                            color: '#000',
                                            opacity: '0.6',
                                        },
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            opacity: '0.7',
                                            fontWeight: 500,
                                            fontSize: '14px',
                                            lineHeight: '23px',
                                        }}
                                    >
                                        {filter.gradeTo === 6
                                            ? 'All grades'
                                            : `${gradeFromTitle} - ${gradeToTitle}`}
                                        {/* {gradeFromTitle} - {gradeToTitle} */}
                                    </Typography>
                                </Button>
                            )}
                            {filters?.includes('adult') && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            data-test-id="adult-checkbox"
                                            icon={<Image src={checkbox} />}
                                            checkedIcon={
                                                <Image src={checkboxChecked} />
                                            }
                                            checked={filter.nonStudent}
                                            onChange={(_, checked) => {
                                                setFilter(prevState => ({
                                                    ...prevState,
                                                    nonStudent: checked,
                                                }))
                                            }}
                                            onClick={e => e.stopPropagation()}
                                            sx={{
                                                '& > img': {
                                                    width: '20px',
                                                    height: '20px',
                                                },
                                            }}
                                        />
                                    }
                                    sx={{
                                        gap: '0',
                                    }}
                                    label="Adults"
                                />
                            )}
                            {filters?.includes('noGrade') && (
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            data-test-id="no-grade-checkbox"
                                            icon={<Image src={checkbox} />}
                                            checkedIcon={
                                                <Image src={checkboxChecked} />
                                            }
                                            checked={filter.noGrade}
                                            onChange={(_, checked) => {
                                                setFilter(prevState => ({
                                                    ...prevState,
                                                    noGrade: checked,
                                                }))
                                            }}
                                            onClick={e => e.stopPropagation()}
                                            sx={{
                                                '& > img': {
                                                    width: '20px',
                                                    height: '20px',
                                                },
                                            }}
                                        />
                                    }
                                    sx={{
                                        gap: '0',
                                    }}
                                    label="No grade"
                                />
                            )}
                        </Stack>
                        {/* <Stack */}
                        {/*    direction="row" */}
                        {/*    alignItems="center" */}
                        {/*    gap="6px" */}
                        {/*    justifyContent="flex-end" */}
                        {/* > */}
                        {/*    <Typography>Sort by:</Typography> */}
                        {/*    <TextField */}
                        {/*        variant="outlined" */}
                        {/*        select */}
                        {/*        defaultValue="Recommended" */}
                        {/*        SelectProps={{ */}
                        {/*            IconComponent: KeyboardArrowDown, */}
                        {/*        }} */}
                        {/*        sx={{ */}
                        {/*            '& .MuiInputBase-root': { */}
                        {/*                '& .MuiSelect-select': { */}
                        {/*                    fontWeight: 700, */}
                        {/*                    padding: '0 25px 0 0', */}
                        {/*                    border: 'none', */}
                        {/*                }, */}
                        {/*                '& .MuiOutlinedInput-notchedOutline': { */}
                        {/*                    border: 'none', */}
                        {/*                }, */}
                        {/*            }, */}
                        {/*        }} */}
                        {/*    > */}
                        {/*        {['Recommended', 'A-z', 'z-A'].map(option => ( */}
                        {/*            <MenuItem key={option} value={option}> */}
                        {/*                {option} */}
                        {/*            </MenuItem> */}
                        {/*        ))} */}
                        {/*    </TextField> */}
                        {/* </Stack> */}
                    </Stack>
                    {caption && (
                        <Stack direction="row" gap="4px">
                            <Typography
                                fontSize="14px"
                                color="#333"
                                lineHeight="148%"
                                data-test-id="search-results-caption"
                            >
                                {`${caption[0]} of over ${caption[1]} results for`}
                            </Typography>
                            <Typography
                                fontSize="14px"
                                color="#333"
                                lineHeight="148%"
                                fontWeight={700}
                                data-test-id="search-results-caption-word"
                            >
                                &quot;{caption[2]}&quot;
                            </Typography>
                        </Stack>
                    )}
                </Stack>
                {loading ? <LinearProgress /> : children}
            </Stack>
        </Stack>
    )
}

export default SearchPageLayout
