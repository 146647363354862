import React, { useRef, useState } from 'react'

import { useFormik } from 'formik'
import {
    Box,
    Button,
    Stack,
    Typography,
    FormControl,
    InputLabel,
    InputBase,
    FormHelperText,
    Checkbox,
    FormControlLabel,
    Switch,
} from '@mui/material'
import { useSnackbar } from 'notistack'

import {
    PostSignUpFormScheme,
    PostSignUpFormInitialValues,
} from './index.schema'
import { PostSignUpFormValues } from './index.types'
import { Dropzone, Form, Image } from 'components/common'
import { useUser, useUserAPI } from 'hooks/user'
import { SchoolType, UserUpdateInput } from 'api/generated'
import { AutocompleteField } from 'components/inputs'

interface PostSignUpProps {
    onSuccess: () => void
}

const PostSignUpExtraForm = ({ onSuccess }: PostSignUpProps): JSX.Element => {
    const { update } = useUserAPI()
    const { enqueueSnackbar } = useSnackbar()

    const dropzoneRef = useRef(null)

    const [file, setFile] = useState<File | null>(null)
    const [preview, setPreview] = useState<string | null | undefined>(null)
    const [isPhotoDeleted, setPhotoDeleted] = useState(false)

    const onFileSelect = () => {
        if (dropzoneRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            dropzoneRef.current.open()
        }
    }

    const onFileRemove = () => {
        setFile(null)
        setPreview(null)
        setPhotoDeleted(true)
    }

    const formik = useFormik<PostSignUpFormValues>({
        initialValues: PostSignUpFormInitialValues,
        validationSchema: PostSignUpFormScheme,
        onSubmit: (values: PostSignUpFormValues) => {
            const input: UserUpdateInput = {
                ...values,
                ...((file || isPhotoDeleted) && {
                    avatar: file,
                }),
            }
            update({
                input,
            }).then(response => {
                if (response?.success) {
                    onSuccess()
                }
            })
        },
    })

    const isSubmitDisabled =
        Object.keys(formik.touched).length > 1 &&
        Object.keys(formik.errors).length > 1

    return (
        <Box>
            <Form onSubmit={formik.handleSubmit}>
                <Stack gap="24px">
                    <Stack gap="25px">
                        <Typography fontSize="15px">
                            Write a couple of sentences about yourself
                        </Typography>
                        <FormControl variant="standard">
                            <InputLabel shrink htmlFor="bio">
                                Bio
                            </InputLabel>
                            <InputBase
                                multiline
                                minRows={8}
                                maxRows={10}
                                id="bio"
                                fullWidth
                                name="bio"
                                placeholder="Bio"
                                value={formik.values.bio}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.bio &&
                                    Boolean(formik.errors.bio)
                                }
                            />
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent={
                                    formik.touched.bio && formik.errors.bio
                                        ? 'space-between'
                                        : 'flex-end'
                                }
                            >
                                {formik.touched.bio && formik.errors.bio && (
                                    <Typography
                                        textAlign="right"
                                        fontSize="12px"
                                        lineHeight="15px"
                                        color={
                                            formik.touched.bio &&
                                            formik.errors.bio
                                                ? 'error'
                                                : '#000'
                                        }
                                    >
                                        {formik.errors.bio}
                                    </Typography>
                                )}
                                <Typography
                                    textAlign="right"
                                    fontSize="12px"
                                    lineHeight="15px"
                                    color={
                                        formik.touched.bio && formik.errors.bio
                                            ? 'error'
                                            : '#000'
                                    }
                                >
                                    {formik.values.bio.length}/255
                                </Typography>
                            </Stack>
                        </FormControl>
                        <Stack direction="row" gap="24px">
                            <Stack
                                position="relative"
                                sx={{
                                    width: '140px',
                                    height: '140px',

                                    borderRadius: '50%',
                                    '.MuiBox-root': {
                                        borderRadius: '50%',
                                    },
                                }}
                            >
                                <Dropzone
                                    ref={dropzoneRef}
                                    PreviewProps={{
                                        sx: {
                                            borderRadius: '50%',
                                            width: '140px',
                                            height: '140px',
                                        },
                                    }}
                                    onSelect={files => {
                                        const preview = URL.createObjectURL(
                                            files[0]
                                        )
                                        setFile(files[0])
                                        setPreview(preview)
                                        setPhotoDeleted(false)
                                    }}
                                    onReject={() => {}}
                                    multiple={false}
                                    preview={preview}
                                />

                                {preview && (
                                    <Image
                                        width={140}
                                        height={140}
                                        sx={{
                                            position: 'absolute',
                                            top: '0',
                                            borderRadius: '50%',
                                        }}
                                        src={preview}
                                    />
                                )}
                            </Stack>
                            <Stack gap="16px">
                                <Button
                                    sx={{
                                        border: '1px solid #E4E4EB',
                                        background: '#F7F8FA',
                                        color: '#1D1E20',
                                        '&:hover': {
                                            background: '#E4E4EB',
                                        },
                                    }}
                                    onClick={onFileSelect}
                                >
                                    Upload photo
                                </Button>
                                <Button
                                    disabled={!file}
                                    variant="outlined"
                                    sx={{
                                        border: '1px solid #C81E1E',
                                        background: 'transparent',
                                        color: '#1D1E20',
                                        '&:hover': {
                                            border: '1px solid #C81E1E',
                                        },
                                    }}
                                    onClick={onFileRemove}
                                >
                                    Delete photo
                                </Button>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack gap="10px">
                        <Button
                            sx={{
                                padding: '11px 16px',
                                fontSize: '15px',
                            }}
                            variant="contained"
                            type="submit"
                            fullWidth
                            disabled={isSubmitDisabled}
                        >
                            Get started
                        </Button>
                        <Button
                            onClick={onSuccess}
                            sx={{
                                padding: '11px 16px',
                                fontSize: '15px',
                            }}
                            variant="outlined"
                            fullWidth
                        >
                            Skip
                        </Button>
                    </Stack>
                </Stack>
            </Form>
        </Box>
    )
}

export default PostSignUpExtraForm
